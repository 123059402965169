import { defineStore } from "pinia";
import {useAppStore as appStore} from "@/store";
import APIRequest from "@/libs/cloud_auth";
import Compressor from "compressorjs";
import axios from "axios";

export const useMediaStore = defineStore("media", {
  state: () => ({
    isLoading: false,
    selectedFilter: [],
    search: "",
    sort: false,
    options: [
      { name: "Image", value: "image" },
      { name: "Video", value: "video" },
    ],
   
  }),

  getters: {
    bytesToSize() {
      return (bytes) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 ' + sizes[0];
        const i = parseInt(Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024)), 10);
        if (i === 0) return `${bytes} ${sizes[i]}`;
        return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
      }
    },
  },
  actions: {
    
    async getAvailableStorage(){
      try {
        const response = await APIRequest(`properties/tenant/total_storage/`, 'post',
          {
            ncm_id: appStore().getCurrentTenant
          }
        )
        return response.data
      } catch(error) {
        console.log(error)
      }
    },
    async getIconsFolder(){
      // this.isLoading = true
  
      try {
        const response = await APIRequest(`media/asset-folder/?name=Icons&source_ref_type=tenant&source_ref_id=${appStore().getCurrentTenant}`)
        if(response.data.count === 0)
          await APIRequest(`media/asset-folder/`, 'post', {
            name: 'Icons',
            source_ref_id: appStore().getCurrentTenant,
            source_ref_type: 'tenant'
        }).then(response => {
          return response.data
        })
        return response.data
      } catch(error) {
        console.log(error)
      } finally {
        // this.isLoading = false
      }
    },
    async getFolders(id, group){
      // this.isLoading = true
      try {
        const response = await APIRequest(`media/asset-folder/?source_ref_type=${group ? 'group' : 'tenant'}&source_ref_id=${id}`)
        return response.data
      } catch(error) {
        console.log(error)
      } finally {
        // this.isLoading = false
      }
    },
    async getAllMedia(id, group){
      try {
        let url = `media/asset/?source_ref_type=${group ? 'group' : 'tenant'}&source_ref_id=${id}`;
        const response = await APIRequest(url)
        
        return response.data
      } catch(error) {
        console.log(error)
      }
    },
    async getMediaResult(id, group, folder, search='', sort=''){
      try {
        let url =
            folder ? `media/asset/?source_ref_type=${group ? 'group' : 'tenant'}&source_ref_id=${id}&folder=${folder}&name__icontains=${search}`
            : `media/asset/?source_ref_type=${group ? 'group' : 'tenant'}&source_ref_id=${id}&folder__isnull=True&name__icontains=${search}`;

        url += sort ? `&ordering=updated_at` : `&ordering=updated_at%20desc`;
        const response = await APIRequest(url)
        
        return response.data
      } catch(error) {
        console.log(error)
      }
    },
    async createFolderAPI(name, id, group){
      try {
        const url = `media/asset-folder/`;
        const response = await APIRequest(url, 'post', {
          "name": name,
          "source_ref_id": id,
          "source_ref_type": group ? 'group' : 'tenant'
        })
        return response.data
      } catch(error) {
        return false;
      }
    },
    async deleteMediaAPI(id) {
      try {
        const url = `media/asset/${id}/`;
        const response = await APIRequest(url, 'delete')
        return response.data
      } catch(error) {
        console.log(error)
      } 
    },
    async deleteFolderAPI(id) {
      try {
        const url = `media/asset-folder/${id}/`;
        const response = await APIRequest(url, 'delete')
        return response.data
      } catch(error) {
        console.log(error)
      } 
    },
    async uploadFileAPI(formData) {
      return new Promise((resolve, reject) => {
        const url = `media/asset/create_asset/`;
        APIRequest(url,'post', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        })
          .then(response => {
            // Resolve the Promise with the response data
            resolve(response.data);
          })
          .catch(error => {
            // Reject the Promise with the error
            reject(error);
          });
      });
    },
    async uploadFileToS3(url, file){
      try {
        await axios.put(url, file, {
           headers: {
             "Content-Type": file.type, // Ensure the Content-Type matches the file type
           },
           withCredentials: false, // Ensure this is false for CORS to work correctly
         });
        } catch (error) {
          console.error('Upload error:', error.response ? error.response.data : error.message);
        }
    },
    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.8,
          maxWidth: 200,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    async newUpload(file, group, directory) {
        
          const generated = await APIRequest('media/asset/generate_presigned_urls/', 'post', 
          {
            "name": file.name,
            "source_ref_type": group ? 'group' : 'tenant',
            "source_ref_id": appStore().getBucket(group),
            "size": file.size,
          })

          let thumbnail = '';
          // if file type contains image/
          if(file.type.includes('image/')) {
            thumbnail = await this.compressImage(file)    
          }
            
          await this.uploadFileToS3(generated.data.file_url, file)

          if(thumbnail)
            await this.uploadFileToS3(generated.data.thumbnail_url, thumbnail)

          let formData = new FormData();
          formData.append("file_key", generated.data.file_key);
          if(thumbnail){
            formData.append("thumbnail_key", generated.data.thumbnail_key);
          }
          formData.append("name", file.name.split(".")[0].slice(0, 50));
          formData.append("source_ref_id", appStore().getBucket(group));
          formData.append("source_ref_type", group ? 'group' : 'tenant');
          formData.append("file_type", file.type);
          formData.append("file_size", file.size);
          if(directory) formData.append("folder", directory);

          await this.uploadFileAPI(formData)

       
        

    },
    async moveToFolderAPI(media_id, folder_id){
      return new Promise((resolve, reject) => {
        const url = `media/asset/${media_id}/`;
        let formData = new FormData();
        formData.append('folder', !folder_id ? '' : folder_id);

        APIRequest(url, 'patch', formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          })
          .then(response => {
            // Resolve the Promise with the response data
            resolve(response.data);
          })
          .catch(error => {
            // Reject the Promise with the error
            reject(error);
          });
      });
    },
    async renameMediaAPI(media_id, new_name){
      return new Promise((resolve, reject) => {
        const url = `media/asset/${media_id}/`;
        let formData = new FormData();
        formData.append('name', new_name);

        APIRequest(url, 'patch', formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          })
          .then(response => {
            // Resolve the Promise with the response data
            resolve(response.data);
          })
          .catch(error => {
            // Reject the Promise with the error
            reject(error);
          });
      });  
    },
    async renameMediaFolderAPI(folder_id, new_name){
      return new Promise((resolve, reject) => {
        const url = `media/asset-folder/${folder_id}/`;
        let formData = new FormData();
        formData.append('name', new_name);

        APIRequest(url, 'patch', formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          })
          .then(response => {
            // Resolve the Promise with the response data
            resolve(response.data);
          })
          .catch(error => {
            // Reject the Promise with the error
            reject(error);
          });
      });  
    },

    // MEDIA PLAYLISTS
    async getPlaylists(){
      this.isLoading = true
      try {
        const response = await APIRequest(`media/media-playlist/?tenant=${appStore().getCurrentTenant}`)
        return response.data
      } catch(error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async getPlaylistById(id){
      this.isLoading = true
      try {
        const response = await APIRequest(`media/media-playlist/${id}/`)
        return response.data
      } catch(error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async createPlaylist(name, media_ids, durations){
      this.isLoading = true
      try {
        const response = await APIRequest(`media/create_playlist/`, 'post', {
          "name": name.trim(),
          "assets": media_ids,
          "durations": durations,
          "tenant": appStore().getCurrentTenant
        })
        return response.data
      } catch(error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    // needs changes
    async editPlaylist(id, name, media_ids, durations){
      this.isLoading = true
      try {
        // patch name
        await APIRequest(`media/media-playlist/${id}/`, 'patch', {
          "name": name
        });

        const response = await APIRequest(`media/edit_playlist_items/?id=${id}`, 'post', {
          "order": media_ids,
          "durations": durations,
        })
        return response.data
      } catch(error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async deletePlaylist(id){
      this.isLoading = true
      try {
        const response = await APIRequest(`media/media-playlist/${id}/`, 'delete')
        return response.data
      } catch(error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
  },
});
